import React from 'react';
import "./index.scss";
import { Modal, Button, Text } from '@opsdti-global-component-library/amgen-design-system';

type ClearAllConfirmationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onClearAll: () => void;
};

export const ClearAllConfirmationModal: React.FC<ClearAllConfirmationModalProps> = ({ isOpen, onClose, onClearAll }) => {
  return (
    <Modal open={isOpen} onCancel={onClose} title="Clear All Articles">
      <div>
        <Text>Are you sure you want to clear all of your selected articles?</Text>
      </div>
      <div className="ext-modal-footer">
        <Button text="CANCEL" type="secondary" style={{ width: "188px", marginRight: "8px"}} onClick={onClose} />
        <Button text="CLEAR ALL ARTICLES" type="primary" style={{ width: "188px"}} onClick={onClearAll} />
      </div>
    </Modal>
  );
};

