import React, { useEffect, useState, } from 'react';
import './index.scss';
import axios from "axios";
import { getApiUrl } from "../../api/api-config";
import { getOktaToken } from "../../utils/commonMethods";
import { noDataMessage } from "../../assets/errorMessageConstants";
import { MoreIntelligence } from './components/more-intelligence';
import { GPTNews } from './components/gpt-news';
import { Title } from '@opsdti-global-component-library/amgen-design-system';
import { GPT_NEWS_TABS, GPT_COMPETITOR_NEWS_TABS_DEFAULT, NewsTabs, Company } from './consts';
import { apiResponse } from '../../utils/commonMethods';
import { AmgenInTheNews } from './components/amgen-in-the-news';
import { EarningsVsFactSet } from './components/earnings-and-factset';
import { Loader } from "@gitlab-rtsensing/component-library";
import {ComparativePEValues} from './components/comparative-pe';
import { PeerSentiment } from './components/peer-sentiment';
import { AmgenStockPerformance } from './components/amgen-stock-performance';

const HomePage: React.FC = () => {
  const [competitorNewsTabs, setCompetitorNewsTabs] = useState<NewsTabs>([])
  const getCompanysList = async () => {
    try {
      const res = await apiResponse("get", "get-company-list", [], {});
      if (res?.data.data) {
        const companyList = res.data.data
        let amgenIndex = -1;
        const tabMap = companyList.map((company: Company, i: number) => {
          if (company.ticker === 'AMGN') {
            amgenIndex = i;
          }
          return {
            key: company.name,
            label: company.name,
            filterName: company.ticker,
            filters: {
              company: [company.ticker]
            }
          }
        })
        tabMap.splice(amgenIndex, 1)
        const tickerList: string[] = tabMap.map((row: any) => row.filters.company[0])
        const tabMapWithAll = [{
          key: 'All',
          label: 'All',
          filterName:'all_competitors',
          filters: {
            company: tickerList
          }
        },
          ...tabMap
        ]
        setCompetitorNewsTabs(tabMapWithAll);
      }
    } catch (error) {
      console.log('SensingGPT Competitor News Fetch Failed', error)
      // Setting defaults if fetch fails
      setCompetitorNewsTabs(GPT_COMPETITOR_NEWS_TABS_DEFAULT)
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getCompanysList()
  }, [])
  const [earningsYear, setYear] = useState<string>("");
  const [earningsQuarter, setQuarter] = useState<string>("");
  const [earningsVsFactSetData, setEarningsVsFactSetData] = useState([]);
  const [earningsVsFactSetIsLoading, setIsLoading] = useState(true);
  const [earningsVsFactSetIsError, setIsError] = useState("");
  const oktaToken = getOktaToken();
  
  const getYearAndQuarter = async () => {
    try {
        const res = await axios({
            method: "get",
            url: `${getApiUrl("time-scale")}`,
            headers: {
                Authorization: `Bearer ${oktaToken}`,
            },
        });
        if (res && res.data && res.data.status === "SUCCESS") {
          const years = Object.keys(res.data.data).map(year => parseInt(year, 10));
          const currentYear = Math.max(...years).toString();
          const currentQuarter = res.data.data[currentYear][0].replace("Q", "");  
          setYear(currentYear);
          setQuarter(currentQuarter);
          getEarningsVsFactSetData(currentYear, currentQuarter);
        } else {
            setIsError(noDataMessage);
        }
    } catch (error) {
      console.log('Earnings vs FactSet Fetch Failed', error)
    } finally {
      setIsLoading(false);
  }
  };

  const getEarningsVsFactSetData = async (year: string, quarter: string) => {
    try {
        const res = await axios({
            method: "get",
            url: `${getApiUrl("get-homepage-earnings-table-data")}?year=${year}&quarter=${quarter}`,
            headers: {
                Authorization: `Bearer ${oktaToken}`,
            },
        });
        if (res && res.data && res.data.status === "SUCCESS") {
            setEarningsVsFactSetData(res.data.data);
        } else {
            setIsError(noDataMessage);
        }
    } catch (error) {
      console.log('Earnings vs FactSet Fetch Failed', error)
    } finally {
        setIsLoading(false);
    }
  };

  useEffect(() => {
    getYearAndQuarter();
  }, [oktaToken]);

  useEffect(() => {
    if (earningsYear && earningsQuarter) {
      getEarningsVsFactSetData(earningsYear, earningsQuarter);
    }
  }, [earningsYear, earningsQuarter]);

  if (earningsVsFactSetIsLoading) {
    return <Loader />;
  }
  
  const competitorNewsCardStyles: { [key: string]: React.CSSProperties } = {
    body: { marginTop: '5px' }
  }

  const competitorNewsRowStyle: React.CSSProperties = {
    paddingTop: "6px",
    marginBottom: "6px"
  }

  return (
    <div className="ext-home-container ext-row">
      <Title className='home-title' level={2}>Amgen Market Update</Title>
      <div className='home-section-container'>
        <div className='home-section-container-primary-tile'>
          <AmgenStockPerformance />
        </div>
        <div className='home-section-container-secondary-tile'>
          <ComparativePEValues 
            popOverContent = "The peerset average excludes Eli Lilly, Novo Nordisk, and Takeda. All P/E multiples are current year estimates, updated daily from FactSet and FMP."
            cardTitle = "Price-to-Earnings Multiple Comparison"
          />
        </div>
      </div>

      <Title className='home-title' level={2}>Latest News</Title>
      <div className='home-section-container'>
        <div className='home-section-container-primary-tile'>
          <GPTNews title='SensingGPT Headlines' tabs={GPT_NEWS_TABS} />
          <AmgenInTheNews/>
        </div> 
        <div className='home-section-container-secondary-tile'>
          <EarningsVsFactSet earningsVsFactSetDataQuarter={earningsQuarter} earningsVsFactSetDataYear={earningsYear} earningsVsFactSetData={earningsVsFactSetData} earningsVsFactSetIsLoading={earningsVsFactSetIsLoading} earningsVsFactSetIsError={earningsVsFactSetIsError}/>  
        </div>
      </div>
      <MoreIntelligence />
      <Title className='home-title' level={2}>Competitor News</Title>
      <div className='home-section-container'>
        <div className='home-section-container-primary-tile'>
          {competitorNewsTabs.length > 0 && <GPTNews title='SensingGPT Competitor News Headlines' tabs={competitorNewsTabs} cardStyles={competitorNewsCardStyles} newsRowStyle={competitorNewsRowStyle} />}
        </div>
        <div className='home-section-container-secondary-tile'>
          <PeerSentiment/>
        </div>
      </div>
      <div style={{margin: '20px 0px'}} />
    </div>
  );
};

export default HomePage;