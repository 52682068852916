import React from "react";
import "./index.scss";
import { Checkbox } from "@opsdti-global-component-library/amgen-design-system";

type Props = {
  label?: string;
  options: any[];
  isLoading: boolean;
  checkedOptions: any[];
  onClickCheckbox: (requestProps: any) => void;
  error: string;
};

const FilterDropdown: React.FC<Props> = (props: Props) => {
  const { label, options, isLoading, checkedOptions, onClickCheckbox, error } =
    props;

  const isChecked = (checkboxId: string) => {
    const filterdOptions = checkedOptions.filter((itemKey) => {
      if (itemKey === checkboxId) {
        return true;
      }
      return false;
    });
    return filterdOptions.length !== 0;
  };

  const selectField = (item: any, label?: string) => {
    if (label?.toUpperCase() === "COMPANY") {
      return item.ticker;
    } else if (label?.toUpperCase() === "PUBLISHER") {
      return item.id;
    } else {
      return item.name;
    }
  }

  return (
    <div className="ext-card border-0">
      <div className="ext-card-body ops-mt-1">
        {isLoading && (
          <div className="ext-d-flex ext-filter-loader ops-mb-6 ops-mt-6">
            <div
              className="spinner-border ext-filter-loader"
              role="status"
            ></div>
          </div>
        )}
        {error && (
          <div className="ext-fw-bold text-secondary ops-px-6 ops-pt-4">
            {error}
          </div>
        )}
        <div>
          {!isLoading &&
            !error &&
            options.map((item: any, index: any) => {
              if (item.name !== undefined) {
                const TrimmedName = item.name.split("_");
                let output = TrimmedName.map((val: any) => {
                  return val.charAt(0).toUpperCase() + val.substring(1);
                });
                output = output.join(" ");
                output = (label?.toUpperCase() === "PUBLISHER") ? item.display_name : output;
                const selectedField = selectField(item, label);
                const isBoxChecked = isChecked(selectedField)
                return (
                  <div key={index} className="ops-mb-2">
                    <Checkbox
                      value={selectedField}
                      checked={isBoxChecked}
                      onChange={(event) => {
                        onClickCheckbox({
                          name: selectedField,
                          field: label,
                          isChecked: event.target.checked,
                          category_name: item.category_name
                        });
                      }}
                    >
                      {label === "KIQs" ? output.toUpperCase() : (output == "Lumakras" || output == "Tezspire") ? output.toUpperCase() : output }
                    </Checkbox>
                  </div>
                );
              }
            })}
        </div>
        {!isLoading && options.length === 0 && !error && (
          <div className="ops-py-2 ops-px-2">{label && label.charAt(0).toUpperCase() + label.slice(1).toLocaleLowerCase()} not found</div>
        )}
      </div>
    </div>
  );
};

export default FilterDropdown;
