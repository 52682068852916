import React from 'react';
import './index.scss';
import {
    BarChart,
    Bar,
    YAxis,
    ResponsiveContainer,
    XAxis,
    Tooltip,
    TooltipProps,
    Legend
} from "recharts";

import { Sentiment } from './index';

interface SentimentChartProps {
    isDataLoading: boolean;
    errorMessage: string;
    sentiments: Sentiment[];
}

const SentimentChart: React.FC<SentimentChartProps> = ({ isDataLoading, errorMessage, sentiments }) => {
    const CustomLegend = (props: any) => {
        const { payload } = props;
        return (
            <div>
                <hr className='ext-divider-bar' />
                <ul style={{ display: 'flex', listStyleType: 'none', paddingLeft: 0 }}>
                    {payload.map((entry: any, index: number) => (
                        <li key={`item-${index}`} style={{ marginRight: 20, display: 'flex', alignItems: 'center' }}>

                            <div style={{ width: 8, height: 8, borderRadius: '50%', backgroundColor: entry.color, marginRight: 5, }} />
                            <span style={{ color: '#000000', fontSize: '10px', fontWeight: '700', lineHeight: '14px' }}>{entry.value === 'Positive Articles' ? 'POSITIVE' : 'NEGATIVE'}</span></li>))}
                </ul>
            </div>
        );
    };

    const SentimentTooltip: React.FC<TooltipProps<number, string> & { payload?: Sentiment[] }> = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="ext-custom-tooltip" style={{ backgroundColor: '#FFFFFF', borderWidth: '1px', padding: '10px' }}>
                    <p className="ext-tootip-label">{`${label}`}</p>
                    <p className='ext-tootltip-line-item' style={{ color: '#FA541C' }}>NEGATIVE ARTICLES: <span style={{ color: '#FA541C', fontWeight: 'bold' }}>{payload[0].value}</span></p>
                    <p className='ext-tootltip-line-item' style={{ color: '#0063C3' }}>POSITIVE ARTICLES: <span style={{ color: '#0063C3', fontWeight: 'bold' }}>{payload[1].value}</span></p>
                </div>
            );
        }
        return null;
    };

    return (
        <div className="ext-article-list-wrapper ext-card-height ext-border-top-0">
            {!isDataLoading && errorMessage && (
                <div className="ext-empty-chart-msg">{errorMessage}</div>
            )}
            {sentiments && sentiments.length > 0 && (
                <div className="ext-sentiment-content">
                    <ResponsiveContainer width='100%' height='100%' minHeight={512}>
                        <BarChart data={sentiments} layout='vertical' barSize={17}>
                            <XAxis type='number' hide />
                            <YAxis
                                width={90}
                                dataKey="company"
                                type='category'
                                tick={{ dx: -85, textAnchor: 'start', fontWeight: 'bold', color: '#141414' }}
                                tickLine={false}
                                axisLine={false}
                            />
                            <YAxis
                                yAxisId="right"
                                type="category"
                                orientation="right"
                                dataKey="sum"
                                tickMargin={30}
                                tick={{ dx: 30, textAnchor: 'end', fontWeight: 'bold', color: '#141414' }}
                                tickFormatter={(value) => `${Math.floor(value)}`}
                                tickLine={false}
                                axisLine={false}
                                width={65}
                            />
                            <Tooltip cursor={{ fill: '#E6F7FF' }} content={<SentimentTooltip />} />
                            <Bar dataKey="negative_count" name="Negative Articles" stackId="a" fill="#FA541C" />
                            <Bar dataKey="positive_count" name="Positive Articles" stackId="a" fill="#0063C3" />
                            <hr className='ext-divider-bar' />
                            <Legend verticalAlign='bottom' content={CustomLegend} />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            )}
        </div>
    );
};

export default SentimentChart;