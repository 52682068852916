import React, { useEffect, useState, useMemo } from 'react';
import './index.scss';
import { Card, Tab } from '@opsdti-global-component-library/amgen-design-system';
import { Loader } from "@gitlab-rtsensing/component-library";
import { apiResponse } from '../../../../utils/commonMethods';
import { apiErrorMessage, noDataMessage } from '../../../../assets/errorMessageConstants';
import SentimentChart from './sentiment-chart';

const POP_OVER_TEXT = "The bar chart analyzes articles ingested over the last 30, 60, or 90 days across all publishers currently available in Sensing. It is updated daily to ensure timely insights."
export class Sentiment {
    company: string;
    positive_count: number;
    negative_count: number;
    sum: number;

    constructor(company: string, positive_count: number, negative_count: number) {
        this.company = company;
        this.positive_count = positive_count;
        this.negative_count = negative_count;
        this.sum = positive_count + negative_count;
    }
}

export const PeerSentiment: React.FC = () => {
    const dayTabKeys = [30, 60, 90];
    const [sentimentListByTab, setSentimentListByTab] = useState<{ [key: number]: Sentiment[] }>({});
    const [errorMessageByTab, setErrorMessageByTab] = useState<{ [key: number]: string }>(
        Object.fromEntries(dayTabKeys.map(day => [day, ""]))
    );
    const [isDataLoading, setIsDataLoading] = useState<boolean>(true);

    const getSentimentData = async () => {
        setIsDataLoading(true);
        let sentimentData: { [key: number]: Sentiment[] } = {};
        for (const day of dayTabKeys) {
            sentimentData[day] = [];
            try {
                const resp = await apiResponse("get", `get-company-sentiment`, [], {}, { days: day });
                if (resp?.data && resp?.data.companies_sentiments) {
                    sentimentData[day] = Object.values(resp.data.companies_sentiments).map((item: any) => new Sentiment(item.company, item.positive_count, item.negative_count));
                } else {
                    setErrorMessageByTab(prevErrorMessagesByTab => ({
                        ...prevErrorMessagesByTab,
                        [day]: noDataMessage,
                    }));
                }
            } catch (error) {
                setErrorMessageByTab(prevErrorMessagesByTab => ({
                    ...prevErrorMessagesByTab,
                    [day]: apiErrorMessage,
                }));
            }
        }        

        setSentimentListByTab(sentimentData);
        setIsDataLoading(false);
    };

    const tabsWithChildren: Tab[] = useMemo(() => {
        return Object.keys(sentimentListByTab).map((day) => ({
            key: day,
            label: `Last ${day} Days`,
            children: <SentimentChart isDataLoading={isDataLoading} errorMessage={errorMessageByTab[Number(day)]} sentiments={sentimentListByTab[Number(day)]} />
        } as Tab));
    }, [sentimentListByTab, isDataLoading, errorMessageByTab]);

    useEffect(() => {
        getSentimentData();
    }, []);

    const cardStyles:  { [key: string]: React.CSSProperties } = { 
        body: { paddingBottom: '8px' } 
    }

    return (
        <div className="ext-peer-sentiment">
            <div className="ext-peer-sentiment-container" style={{ width: '100%' }}>
                <Card
                    title={{
                        title: 'Peer Sentiment: Perception by Articles',
                        popoverContent: POP_OVER_TEXT,
                        popoverIconColor: "secondary",
                    }}
                    divider={false}
                    tabList={tabsWithChildren}
                    defaultActiveTabKey={dayTabKeys[0].toString()}
                    styles={cardStyles}
                >
                    <div className="ext-article-list-wrapper ext-card-height ext-border-top-0" >
                        {isDataLoading && <Loader />}
                    </div>
                </Card>
            </div>
        </div>
    )
}