import React, { useState } from "react";
import "./index.scss";

// API
import { apiResponse } from "../../../../utils/commonMethods";

// Assets
import {
  apiErrorMessage,
 emailErrorMessage
} from "../../../../assets/errorMessageConstants";

// Components
import { Loader } from "@gitlab-rtsensing/component-library";
import { Button, Input, Label, Modal, TextArea, Text } from '@opsdti-global-component-library/amgen-design-system'

type ShareArticleModalProps = {
  isOpen: boolean;
  onClose: () => void;
  selectedArticles: any[];
  resetSelectedArticles: () => void;
};

export const ShareArticleModal: React.FC<ShareArticleModalProps> = (
  props: ShareArticleModalProps
) => {
  const {
    isOpen,
    onClose,
    selectedArticles,
    resetSelectedArticles
  } = props;

  const [loaderOnShareArticles, setLoaderOnShareArticles] = useState(false);
  const [errorOnShareArticles, setErrorOnShareArticles] = useState("");
  const [recipients, setRecipients] = useState("");
  const [recipientsEmailError, setRecipientsEmailError] = useState(false);
  const [comments, setComments] = useState("");
  const [isEmailSending, setIsEmailSending] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);

  const handleShareArticles = async () => {
    const validateEmail = (email: string): boolean => {
      const parts = email.split("@");
      if (parts.length !== 2) return false;
    
      const [localPart, domainPart] = parts;
      if (!localPart || !domainPart) return false;
    
      const domainParts = domainPart.split(".");
      if (domainParts.length < 2) return false;
    
      return domainPart.endsWith("@amgen.com");
    };

    const recipientsEmailArray = recipients.split(",").map(email => email.trim());
    const validRecipientEmails = recipientsEmailArray.filter((email) => {
      if (validateEmail(email)) {
        return true;
      }
      return false;
    });
    if (recipientsEmailArray.length > validRecipientEmails.length) {
      setRecipientsEmailError(true);
      setErrorOnShareArticles(emailErrorMessage);
    } else {
      setRecipientsEmailError(false);
      setErrorOnShareArticles("");
    }

    setLoaderOnShareArticles(true);
    setErrorOnShareArticles("");
    setIsEmailSending(true);
    setIsEmailSent(false);
 
  try {
    const payload = {
      articles: selectedArticles,
      recipients: recipients.split(",").map(email => email.trim()),
      comments: comments,
    }

    const res = await apiResponse("post", "send_articles_email", [], payload);

    if (res.data.status === "SUCCESS") {
      if (res.data.data.error) {
        console.error("Error in response data:", res.data);
        setErrorOnShareArticles(res.data.data.error);
        setIsEmailSending(false);
        setIsEmailSent(false);
        setRecipientsEmailError(false);
      } else {
        console.log("Articles shared successfully:", res.data);
        setRecipients("");
        setComments("");
        setIsEmailSent(true);
        setIsEmailSending(false);
        setRecipientsEmailError(false);
        setErrorOnShareArticles("");
      }
    } else {
      setErrorOnShareArticles(res.data.data.error.message);
      setIsEmailSending(false);
      setIsEmailSent(false);
    }
  } catch (error) {
    console.error("Error sharing articles:", error);
    setErrorOnShareArticles(apiErrorMessage);
    setIsEmailSent(false);
    setIsEmailSending(false);
  } finally {
    setLoaderOnShareArticles(false);
  }
};

  return (
    <>
      {!isEmailSent && (
        <Modal
          open={isOpen}
          onCancel={() => {
            onClose();
            setRecipientsEmailError(false);
            setErrorOnShareArticles("");
          }}
          title={
            isEmailSent ? "Articles Shared" : "Share Articles"
          }
        >
          {loaderOnShareArticles || isEmailSending ? (
            <div className="ext-share-articles-loader">
              <Loader />
            </div>
        ) : isEmailSent ? (
          <div className="ext-share-articles-confirmation">
            <Text className="ext-articles-shared-modal-confirmation-text">Your articles have been emailed to your recipients.</Text>
            <div className="ext-modal-navigation-wrapper">
              <Button
                text="CONTINUE"
                style={{ width: "188px" }}
                onClick={() => {
                  onClose();
                  resetSelectedArticles();
                  setIsEmailSent(false);
                }}
              />
            </div>
          </div>
        ) : (
            <div className="ext-share-articles-modal-responsive">
              <div className="ext-share-articles-modal-container">
                <div className="ext-share-articles-modal-recipients-wrapper">
                  <Label className="ext-share-articles-modal-recipients-label">
                    RECIPIENTS
                  </Label>
                  <Input
                    placeholder="Enter email addresses, separated by a comma"
                    value={recipients}
                    onChange={(e) => {
                      setRecipients(e.target.value);
                      setRecipientsEmailError(false);
                      setErrorOnShareArticles(""); 
                    }}
                  />
                  {!isEmailSent &&
                    (recipientsEmailError || errorOnShareArticles) && (
                      <div className="ext-share-articles-error">
                        {recipientsEmailError
                          ? emailErrorMessage
                          : apiErrorMessage}
                      </div>
                    )}
                </div>
                <div className="ext-share-articles-modal-comments-wrapper">
                  <div className="ext-share-articles-modal-comments-label-wrapper">
                    <Label className="ext-share-articles-modal-comments-label">
                      COMMENTS
                    </Label>
                    <Label colorVariant="secondary">(OPTIONAL)</Label>
                  </div>
                  <TextArea
                    placeholder="Enter any comments here"
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                  />
                </div>
                <div className="ext-modal-navigation-wrapper">
                  <Button
                    text="CANCEL"
                    type="secondary"
                    style={{ width: "188px", marginRight: "8px" }}
                    onClick={() => {
                      onClose();
                      setRecipientsEmailError(false);
                      setErrorOnShareArticles("");
                    }}
                  />
                  <Button
                    text="SEND"
                    style={{ width: "188px" }}
                    onClick={handleShareArticles}
                    disabled={!recipients.trim()}
                  />
                </div>
              </div>
            </div>
          )}
        </Modal>
      )}
    </>
  );
};