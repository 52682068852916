import React, { useContext } from "react";

// Components
import RequestPage from "../../components/request-access-page";
// CSS
import "./index.scss";

// Utils
import { AppContext } from "../../utils/app-context";
import KnowledgeGraph from "../../components/knowledge-graph";

type Props = {};

const Experimental: React.FC<Props> = (props: Props) => {
  const { authData } = useContext(AppContext);

  return authData && authData?.externalAuthorization?.experimentalPage ? (
    <div className="ext-news-container-experimental ext-row ops-px-3 ops-my-2">
      <div className="ext-experiment-card ext-card card ext-col-lg-12 ext-col-md-12 ops-mt-5 ops-mt-4 ops-mb-4">
        <div className="ops-mt-3 ops-mb-3">
          <span className="ext-experiment-card-header">EXPERIMENTAL :</span>
          <span className="ext-experiment-card-sub-header">
            This output is for illustrative purposes and not yet suitable for
            decision making. Errors and omissions may be present.
          </span>
        </div>
      </div>

      <div className="ext-sensing-qa-header ops-mb-3  ops-mt-4">
        Experimental Features
      </div>

      <div className="ext-row">
        <div className="ops-mt-4 ext-col-lg-4 ext-col-md-12">
          <KnowledgeGraph />
        </div>
        <div className="ext-col-4" />
      </div>
    </div>
  ) : (
    <>{authData && <RequestPage />}</>
  );
};
export default Experimental;
